// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-chords-js": () => import("./../../../src/pages/chords.js" /* webpackChunkName: "component---src-pages-chords-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lyrics-js": () => import("./../../../src/pages/lyrics.js" /* webpackChunkName: "component---src-pages-lyrics-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-artists-js": () => import("./../../../src/templates/Artists.js" /* webpackChunkName: "component---src-templates-artists-js" */),
  "component---src-templates-chord-js": () => import("./../../../src/templates/Chord.js" /* webpackChunkName: "component---src-templates-chord-js" */),
  "component---src-templates-chords-by-artist-js": () => import("./../../../src/templates/ChordsByArtist.js" /* webpackChunkName: "component---src-templates-chords-by-artist-js" */),
  "component---src-templates-lyrics-by-artist-js": () => import("./../../../src/templates/LyricsByArtist.js" /* webpackChunkName: "component---src-templates-lyrics-by-artist-js" */),
  "component---src-templates-song-js": () => import("./../../../src/templates/Song.js" /* webpackChunkName: "component---src-templates-song-js" */)
}

